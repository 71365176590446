import * as React from "react";

export default function Checkbox(props: {
  width: number;
  height: number;
  checked: boolean;
  imageUrl: string;
}): React.ReactElement {
  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        display: "inline-block",
      }}
    >
      <img
        src={props.imageUrl}
        width={props.width}
        height={props.height}
        style={{
          border: props.checked ? "3px solid #600" : "3px solid transparent",
          borderRadius: 50,
          opacity: props.checked ? 1.0 : 0.5,
        }}
      />
    </div>
  );
}
