import * as React from "react";
import { Animal } from "./DataModel";
import MyAnimalRow from "./MyAnimalRow";
import AnimalsReferential from "./AnimalsReferential.json";

type Flag = ":checked" | "!checked";

export default function MyAnimals(props: {
  maxWidth: number;
}): React.ReactElement {
  const filterElement = React.useRef<HTMLInputElement>(null);
  const newZooElement = React.useRef<HTMLInputElement>(null);

  const [filter, setFilter] = React.useState<Array<string>>([]);
  const [flags, setFlags] = React.useState<Array<Flag>>([]);

  const [animals, setAnimals] = React.useState<Array<Animal>>(() => {
    const data = localStorage.getItem("animals");
    if (data === null) return [];
    return JSON.parse(data) as Array<Animal>;
  });

  const [zoos, setZoos] = React.useState<Array<string>>(() => {
    const data = localStorage.getItem("zoos");
    if (data === null) return [];
    return JSON.parse(data) as Array<string>;
  });

  let rows = zoos.flatMap((zoo) =>
    AnimalsReferential.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    ).map((animalRef) => ({
      id: animalRef.id,
      name: animalRef.name,
      nameParts: animalRef.name.toLowerCase().split(" "),
      category: animalRef.category.title,
      region: animalRef.region.title,
      imageUrl: animalRef.image.url,
      zoo: zoo,
      checked:
        animals.find((x) => x.id === animalRef.id && x.zoo === zoo) !==
        undefined,
    }))
  );

  const concatFilter = filter.concat(flags);
  if (concatFilter.length > 0) {
    rows = rows.filter((x) =>
      concatFilter.every((f) =>
        f === ":checked"
          ? x.checked
          : f === "!checked"
          ? !x.checked
          : x.zoo.toLowerCase().startsWith(f) ||
            x.nameParts.find((p) => p.startsWith(f)) !== undefined ||
            x.category.toLowerCase().startsWith(f) ||
            x.region.toLowerCase().startsWith(f)
      )
    );
  }

  return (
    <div
      style={{
        maxWidth: props.maxWidth,
        display: "flex",
        flexDirection: "column",
        gap: 32,
        padding: "32px 0",
      }}
    >
      <h1>My Zoos</h1>
      <ul>
        {zoos.map((zoo) => (
          <li key={zoo}>
            <label>{zoo}</label>
            <button
              onClick={() => {
                if (
                  window.confirm(`Delete zoo ${zoo} and all saved animals?`)
                ) {
                  const newZoos = zoos.filter((x) => x !== zoo);
                  localStorage.setItem("zoos", JSON.stringify(newZoos));
                  setZoos(newZoos);
                }
              }}
            >
              Delete
            </button>
          </li>
        ))}
        <li>
          <input ref={newZooElement} type="text" />
          <button
            onClick={() => {
              const input = newZooElement.current;
              if (input && input.value) {
                const newZoos = [...zoos, input.value].sort((a, b) =>
                  a < b ? -1 : a > b ? 1 : 0
                );
                localStorage.setItem("zoos", JSON.stringify(newZoos));
                setZoos(newZoos);
                input.value = "";
              }
            }}
          >
            Create
          </button>
        </li>
      </ul>

      <h1>My Animals</h1>
      <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
        <label htmlFor="MyAnimals_filter">Filter:</label>
        <input
          id="MyAnimals_filter"
          ref={filterElement}
          type="text"
          autoFocus
          onChange={(e) => {
            setFilter(e.target.value.toLowerCase().split(" "));
          }}
          style={{
            flexGrow: 1,
          }}
        ></input>
      </div>
      <div style={{}}>
        {[
          { flag: ":checked" as Flag, title: "Checked" },
          { flag: "!checked" as Flag, title: "Not checked" },
        ].map((f) => {
          const flagChecked = flags.includes(f.flag);
          return (
            <button
              style={{
                borderColor: flagChecked ? "#000" : undefined,
                fontWeight: flagChecked ? 900 : undefined,
                background: flagChecked ? "#fff" : undefined,
              }}
              onClick={() => {
                if (flagChecked) {
                  setFlags(flags.filter((x) => x !== f.flag));
                } else {
                  setFlags([f.flag]);
                }
              }}
              key={f.flag}
            >
              {f.title}
            </button>
          );
        })}
      </div>

      <table
        style={{
          border: "0",
          borderCollapse: "collapse",
          tableLayout: "fixed",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: 80 + 8, padding: "0 4px" }}></th>
            <th
              style={{
                width: (props.maxWidth - 80 - 8) / 4,
                padding: "0 4px",
              }}
            >
              Zoo
            </th>
            <th
              style={{
                width: (props.maxWidth - 80 - 8) / 4,
                padding: "0 4px",
              }}
            >
              Animal
            </th>
            <th
              style={{
                width: (props.maxWidth - 80 - 8) / 4,
                padding: "0 4px",
              }}
            >
              Category
            </th>
            <th
              style={{
                width: (props.maxWidth - 80 - 8) / 4,
                padding: "0 4px",
              }}
            >
              Region
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <MyAnimalRow
              key={`${row.zoo}:${row.id}`}
              zoo={row.zoo}
              id={row.id}
              name={row.name}
              region={row.region}
              category={row.category}
              imageUrl={row.imageUrl}
              checked={row.checked}
              onChecked={(checked) => {
                let newAnimals: Animal[];

                if (checked) {
                  newAnimals = [...animals, { id: row.id, zoo: row.zoo }];
                } else {
                  newAnimals = animals.filter(
                    (x) => !(x.id === row.id && x.zoo === row.zoo)
                  );
                }

                localStorage.setItem("animals", JSON.stringify(newAnimals));
                setAnimals(newAnimals);
              }}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
