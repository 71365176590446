import React from "react";
import ReactDOM from "react-dom/client";
import MyAnimals from "./MyAnimals";
import "./index.css";

const rootElement = document.getElementById("root") as HTMLElement;

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <MyAnimals maxWidth={Math.min(rootElement.clientWidth, 800)} />
  </React.StrictMode>
);
