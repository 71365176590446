import * as React from "react";
import Checkbox from "./Checkbox";

export default function MyAnimalRow(props: {
  id: string;
  zoo: string;
  name: string;
  imageUrl: string;
  category: string;
  region: string;
  checked: boolean;
  onChecked: (checked: boolean) => void;
}): React.ReactElement {
  return (
    <tr
      onClick={() => {
        props.onChecked(!props.checked);
      }}
      style={{
        cursor: "pointer",
        background: props.checked ? "#fdd" : "transparent",
      }}
    >
      <td style={{ padding: "4px 4px" }}>
        <Checkbox
          width={80}
          height={80}
          imageUrl={props.imageUrl}
          checked={props.checked}
        />
      </td>
      <td style={{ padding: "0 4px" }}>{props.zoo}</td>
      <td style={{ padding: "0 4px" }}>{props.name}</td>
      <td style={{ padding: "0 4px" }}>{props.category}</td>
      <td style={{ padding: "0 4px" }}>{props.region}</td>
    </tr>
  );
}
